import { template as template_493dce3fb26b4c0690f4e4dd4f9bd92a } from "@ember/template-compiler";
const WelcomeHeader = template_493dce3fb26b4c0690f4e4dd4f9bd92a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
