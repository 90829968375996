import { template as template_763910da422641719f2f68b0e4fdbf2e } from "@ember/template-compiler";
const FKControlMenuContainer = template_763910da422641719f2f68b0e4fdbf2e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
