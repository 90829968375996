import { template as template_fedab63a75a44b468c58a1648d3b7d43 } from "@ember/template-compiler";
const FKText = template_fedab63a75a44b468c58a1648d3b7d43(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
